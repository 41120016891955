.custom-slider .rc-slider-track {
  background: linear-gradient(310deg, #7928ca, #ff0080);
}

.custom-slider .rc-slider-handle {
  background-color: #ffffff; /* Set the desired handle color */
  border-color: #000000; /* Set the desired handle border color */
}

/* Style the button used to toggle the collapsible content */
.collapsible {
  background-color: #f2f2f7;
  color: black;
  cursor: pointer;
  padding: 12px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  margin-top: 5px;
}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0 15px;
  display: none;
  overflow: hidden;
  background-color: #f2f2f7;
  transition: max-height 0.2s ease-out;
}

/* Add some hover effects to buttons */
.collapsible:hover {
  background-color: #fff;
}

.carousel-item {
  height: 100%;
  /* Adjust height as needed */
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
}

.carousel-text {
  color: black;
}

.carousel-container {
  padding: 0 20px;
  /* Adjust padding as needed */
  position: relative;
}

.carousel-control-prev,
.carousel-control-next {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}

.carousel-control-prev {
  left: 20px;
  /* Adjust left padding */
}

.carousel-control-next {
  right: 20px;
  /* Adjust right padding */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: white;
}

.carousel-indicators .active {
  background-color: #191970;
  /* Change color to #191970 */
}

.carousel-indicators li {
  background-color: #c0c0c0;
  /* Change color to gray */
}

.customModalWidth {
  width: 90vw;
  max-width: 90vw;
}
